import React from 'react'
import { t, Trans } from "@lingui/macro"

import UrbanSeedling from "../components/imageUrbanSeedling"
import BrasserieLucilles from "../components/imageBrasserieLucillesSite"
import CasanovaCard from "../components/imageCasanovaCard"
import GardenPlanner from "../components/imageGardenPlanner"
import LucillesOyster from "../components/imageLucillesOysterSite"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDoubleRight, faEnvelope } from "@fortawesome/free-solid-svg-icons"

const PROJECTS =  [
  {
    name: <Trans>Urban Seedling</Trans>,
    description: <Trans>A blog and online shop implemented in WordPress.</Trans>,
    imagePath: <UrbanSeedling />,
    href: "https://www.urbanseedling.com",
    linkText: <Trans>Visit on the web </Trans>,
    linkIcon: <FontAwesomeIcon icon={faAngleDoubleRight} />,

  },
  {
    name: "Groupe Casanova Construction",
    description: <Trans>Business card design and print work with edge coloring.</Trans>,
    imagePath: <CasanovaCard />,
  },
  {
    name: "Brasserie Lucille's",
    description: <Trans>Web brochure for Lucille's latest restaurant offering.</Trans>,
    imagePath: <BrasserieLucilles />,
    href: "https://www.brasserielucilles.com",
    linkText: <Trans>Visit on the web </Trans>,
    linkIcon: <FontAwesomeIcon icon={faAngleDoubleRight} />,
  },
  {
    name: <Trans>Garden Planner Web Application</Trans>,
    description: <Trans>A tool for Urban Seedling and its clients to create vegetable garden plans with multiple planting seasons.</Trans>,
    imagePath: <GardenPlanner />,
    href: <Trans>mailto:admin@urbanseedling.com?subject=Requesting Beta Access</Trans>,
    linkText: <Trans>Request beta access </Trans>,
    linkIcon: <FontAwesomeIcon icon={faEnvelope} />,
  },
  {
    name: "Lucille's Oyster Dive",
    description: <Trans>A refresh of the 2010 brochure design. Work in progress...</Trans>,
    imagePath: <LucillesOyster />,
    href: "https://dive.lucillesoyster.com",
    linkText: <Trans>Visit on the web </Trans>,
    linkIcon: <FontAwesomeIcon icon={faAngleDoubleRight} />,
  },
]

export default PROJECTS
