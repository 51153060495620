import React from "react"
import { Trans } from "@lingui/macro"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Projects from "../components/projects"
import BG from "../components/bg"
import Pos from "../components/pos"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"

import styles from "./index.module.css"


const IndexPage = (props) => {
  const lang = props.pageContext.lang
  const description = lang === 'en' ? `Web development and design by mrmaru` : `Conception de site web par mrmaru`
  return (
  <Layout {...props}>
    <SEO 
      lang={lang} 
      title="Web Developer" 
      description={description}
      keywords={[`Web developer`, `Graphic Design`, `Web Application Development`]} 
      
    />
    <div className={styles.hero + ' hero'}>
      <BG/>
      <div className={styles.heroPanel + ` panel`}>
        <Pos>
            <div>
            <h1><Trans>LEVERAGE FIRST CLASS PROBLEM SOLVING SKILLS</Trans></h1>
            <Trans>Have some web related work to do? <br/> <a href="mailto:glenn@mrmaru.com?subject=Project Discussion">Let's chat <FontAwesomeIcon icon={faEnvelope}/></a></Trans>
            </div>
        </Pos>
      </div>
      <div className={styles.logo}>
        <Pos>
          <div>mrmaru</div>
        </Pos>
      </div>
    </div>

    <Projects />

  </Layout>
)
      }

export default IndexPage
