import React, { Component } from 'react';
import PropTypes from 'prop-types';

const UPDATE_INTERVAL = 16 //60fps?

export default class Counter extends Component {
  constructor() {
    super();

    // Always not visible while server rendering.
    this.state = {
      ScrollYSupported: false,
      pos: 0,
    };
  }

  async componentDidMount() {
    // Default values
    let ScrollYSupported = false
    let pos = 0

    // scrollY polyfill

    // Check if browser (now) supports scrollY
    if (typeof window !== 'undefined' && window.scrollY === 0) {
      ScrollYSupported = true
      this.timerID = setInterval(
        () => this.updateCounter(window.scrollY),
        UPDATE_INTERVAL
      )
    }

    this.setState(
      {
        ScrollYSupported,
        pos,
      },
    )
  }

  updateCounter = pos => this.setState({ pos })

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  shouldComponentUpdate() {
    if(window.scrollY > 300) {
      if(this.state.pos < 300) {
        this.setState({ pos: 300 })
        return true
      }
      return false
    }
    return true
  }

  render() {
    const { pos } = this.state;
    const opacity = 1 - ((pos / 3) / 100)
    const translateY = (pos / 3) 

    return (
      <div id="pos" style={{
        transform: `translateY(${translateY}px)`, 
        opacity: opacity,
        willChange: `transform, opacity`,
      }}>
        {this.props.children}
      </div>
    );
  }
}

Counter.propTypes = {
  children: PropTypes.object.isRequired,
};
