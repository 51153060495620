import React from "react"
import { Trans } from "@lingui/macro"

import PROJECTS from "../data/Projects"

import styles from "./projects.module.css"

const Project = ({ className, entry}) => (
  <div className={styles.section + ` ` + className}>

    <div className={styles.imageBox}>
      {entry.imagePath}
    </div>

    <div className={styles.infoBox}>

      <h2 className={ styles.title }>
        {entry.name}
      </h2>

      <div className={styles.description}>
        {entry.description}
      </div>

      <div className={styles.link}>
        <a href={entry.href} subject="Project Discussion">
          {entry.linkText} {entry.linkIcon}
        </a>
      </div>

    </div>

  </div>
)

const Projects = () => (
  <div className={styles.showcase}>
    { PROJECTS.map((item,i) => 
      <Project entry={item} key={item.name + i} />
    )}
  </div>
)

export default Projects
